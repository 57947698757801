import React, { FC } from 'react';
import styled from 'styled-components';

type YoutubeVideoProps = PropsWithStyle & YoutubeVideoSrc;

const YoutubeVideo: FC<YoutubeVideoProps> = ({ src, style, className }: YoutubeVideoProps) => {
  return (
    <div style={style} className={className}>
      <ProportionalWrapper>
        <VideoIFrameContainer>
          <VideoIFrame src={src} />
        </VideoIFrameContainer>
      </ProportionalWrapper>
    </div>
  );
};

const ProportionalWrapper = styled.div`
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
`;

const VideoIFrameContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const VideoIFrame = styled.iframe.attrs(() => ({
  frameBorder: '0',
  allow: 'encrypted-media',
  allowFullScreen: true,
}))`
  width: 100%;
  height: 100%;
`;

export default YoutubeVideo;
