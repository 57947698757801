import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import GatsbyImage from '../../gatsby/GatsbyImage';

const WorkHeaderLogo: FC<PropsWithStyle> = props => {
  return (
    <Link to="/">
      <WorkHeaderLogoWrapper {...props}>
        <WorkHeaderLogoImage imagePath="logo.png" />
      </WorkHeaderLogoWrapper>
    </Link>
  );
};

const WorkHeaderLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const WorkHeaderLogoImage = styled(GatsbyImage)`
  width: 100%;
  height: auto;
`;

export default WorkHeaderLogo;
