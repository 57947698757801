import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import Img from 'gatsby-image/withIEPolyfill';

type GatsbyImageSizeType = 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';

interface GatsbyImageProps extends PropsWithStyle {
  /** image path in relative to `src/images/` directory */
  imagePath: string;
  /** image size type: 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL'
   *  inferred from original image size by default*/
  sizeType?: GatsbyImageSizeType;
  /** image alt text */
  alt?: string;
  /** browser’s native lazy loading attribute: 'lazy' | 'eager' | 'auto' */
  loading?: 'lazy' | 'eager' | 'auto';
  /** stop the default fading in the image on load */
  withoutFadeIn?: boolean;
  /** draggable image */
  draggable?: boolean;
  /** image object fit */
  objectFit?: `fill` | `contain` | `cover` | `none` | `scale-down`;
}

interface GatsbyImageFluidNode {
  relativePath: string;
  childImageSharp: {
    fluid: FluidObject;
  };
}

interface GatsbyImageOriginalWidthNode {
  relativePath: string;
  childImageSharp: {
    original: { width: number };
  };
}

interface GatsbyImageFluidEdge {
  node: GatsbyImageFluidNode;
}

interface GatsbyImageOriginalWidthEdge {
  node: GatsbyImageOriginalWidthNode;
}

type GatsbyImageOriginalWidthQueryData = {
  original: { edges: Array<GatsbyImageOriginalWidthEdge> };
};

type GatsbyImageFluidQueryData = {
  [key in GatsbyImageSizeType]: { edges: GatsbyImageFluidEdge[] };
};

type GatsbyImageQueryData = GatsbyImageOriginalWidthQueryData & GatsbyImageFluidQueryData;

const GatsbyImage: FC<GatsbyImageProps> = ({
  imagePath,
  alt = '',
  style = {},
  className = '',
  sizeType,
  loading = 'lazy',
  withoutFadeIn = false,
  draggable = false,
  objectFit = undefined,
}: GatsbyImageProps) => {
  const queryData = useStaticQuery<GatsbyImageQueryData>(graphql`
    query {
      original: allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              original {
                width
              }
            }
          }
        }
      }
      XXL: allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      # XL: allFile {
      #   edges {
      #     node {
      #       relativePath
      #       childImageSharp {
      #         fluid(maxWidth: 1024, quality: 90) {
      #           ...GatsbyImageSharpFluid_withWebp
      #         }
      #       }
      #     }
      #   }
      # }
      # L: allFile {
      #   edges {
      #     node {
      #       relativePath
      #       childImageSharp {
      #         fluid(maxWidth: 512, quality: 90) {
      #           ...GatsbyImageSharpFluid_withWebp
      #         }
      #       }
      #     }
      #   }
      # }
      # M: allFile {
      #   edges {
      #     node {
      #       relativePath
      #       childImageSharp {
      #         fluid(maxWidth: 256, quality: 90) {
      #           ...GatsbyImageSharpFluid_withWebp
      #         }
      #       }
      #     }
      #   }
      # }
      # S: allFile {
      #   edges {
      #     node {
      #       relativePath
      #       childImageSharp {
      #         fluid(maxWidth: 128, quality: 90) {
      #           ...GatsbyImageSharpFluid_withWebp
      #         }
      #       }
      #     }
      #   }
      # }
      # XS: allFile {
      #   edges {
      #     node {
      #       relativePath
      #       childImageSharp {
      #         fluid(maxWidth: 64, quality: 90) {
      #           ...GatsbyImageSharpFluid_withWebp
      #         }
      #       }
      #     }
      #   }
      # }
    }
  `);

  const imageOriginalWidthEdge = queryData.original.edges.find(({ node }) => {
    return node.relativePath.includes(imagePath);
  });

  let defaultSizeType: GatsbyImageSizeType = 'XXL';

  if (imageOriginalWidthEdge) {
    const { width } = imageOriginalWidthEdge.node.childImageSharp.original;
    if (width < 64 * 2) {
      defaultSizeType = 'XS';
    } else if (width < 128 * 2) {
      defaultSizeType = 'S';
    } else if (width < 256 * 2) {
      defaultSizeType = 'M';
    } else if (width < 512 * 2) {
      defaultSizeType = 'L';
    } else if (width < 1024 * 2) {
      defaultSizeType = 'XL';
    }
  }

  defaultSizeType = 'XXL';

  const { edges } = queryData[sizeType ?? defaultSizeType];

  const imageFluidEdge = edges.find(({ node }) => {
    return node.relativePath === imagePath;
  });

  if (!imageFluidEdge) {
    return null;
  }

  return (
    <Img
      alt={alt}
      fluid={imageFluidEdge.node.childImageSharp.fluid}
      style={{ background: 'none', ...style }}
      className={className}
      loading={loading}
      fadeIn={!withoutFadeIn}
      draggable={draggable}
      objectFit={objectFit}
    />
  );
};

export default GatsbyImage;
