/* global jwplayer */
import React, { FC, useRef, useLayoutEffect } from 'react';

type JWPlayerProps = PropsWithStyle & JWPlayerSrc;

declare global {
  interface Window {
    jwplayer?: jwplayer.JWPlayerStatic;
  }
}

const JWPlayer: FC<JWPlayerProps> = ({ videoSrc, imageSrc, style }: JWPlayerProps) => {
  const ref = useRef(null);

  useLayoutEffect(() => {
    const existingJwplayerScript = document.getElementById('JWplayerScript');
    const initThisPlayer = (): void => {
      jwplayer(ref?.current ?? undefined).setup({
        file: videoSrc,
        image: imageSrc,
      });
    };
    if (!existingJwplayerScript || !window.jwplayer) {
      const script = document.createElement('script');
      script.src = 'https://cdn.jwplayer.com/libraries/JM35W9ju.js';
      script.id = 'JWplayerScript';
      script.async = true;
      script.onload = initThisPlayer;
      document.body.appendChild(script);
    } else {
      initThisPlayer();
    }
  }, []);

  return (
    <div style={{ ...style }}>
      <div ref={ref} />
    </div>
  );
};

export default JWPlayer;
