import { css, CSSObject, FlattenSimpleInterpolation, SimpleInterpolation } from 'styled-components';

/* We adopt a min-width based media query system:
   XS: default mobile-first
   S: @media (min-width: 568px)
   M: @media (min-width: 768px)
   L: @media (min-width: 1200px)
   XL: @media (min-width: 1440px)
*/

export const deviceSizes = {
  S: 568,
  M: 768,
  L: 1200,
  XL: 1440,
};

const mediaDevice = Object.fromEntries(
  // Iterate through the sizes and create a media template
  Object.entries(deviceSizes).map(([key, value]) => [
    key,
    (
      first: TemplateStringsArray | CSSObject,
      ...interpolations: SimpleInterpolation[]
    ): FlattenSimpleInterpolation => css`
      @media screen and (min-width: ${value}px) {
        ${css(first, ...interpolations)}
      }
    `,
  ]),
);

export default mediaDevice;
