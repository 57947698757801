import { MutableRefObject, useState, useLayoutEffect } from 'react';
import elementResizeDetectorMaker from 'element-resize-detector';

interface ComponentSize {
  width?: number;
  height?: number;
}

let erd: elementResizeDetectorMaker.Erd;

const useComponentSize = (ref: MutableRefObject<HTMLElement | null>): ComponentSize => {
  const [componentSize, setComponentSize] = useState<ComponentSize>({});
  const handleResize = (): void => {
    setComponentSize({
      width: ref?.current?.offsetWidth,
      height: ref?.current?.offsetHeight,
    });
  };
  useLayoutEffect(() => {
    handleResize();
    if (!erd) {
      erd = elementResizeDetectorMaker({
        strategy: 'scroll', // <- For ultra performance.
      });
    }
    if (ref?.current) {
      erd.listenTo(ref.current, handleResize);
    }
    return (): void => {
      if (ref?.current) {
        erd.removeListener(ref.current, handleResize);
      }
    };
  }, []);
  return componentSize;
};

export default useComponentSize;
