import { createGlobalStyle } from 'styled-components';
import { deviceSizes } from './mediaDevice';

export default createGlobalStyle`
  html, body {
    width: 100%;
    background-color: black;
    color: white;
    font-family: Replica-Regular;
  }
  div#___gatsby {
    margin: 0 auto;
    width: 100%;
    max-width: ${deviceSizes.XL}px;
    position: relative;
    z-index: 0;
    animation: fadein 2s;
  }
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
`;
