import React, { FC, Fragment } from 'react';
import styled from 'styled-components';

import usePageContainerWidthContext from '../../hooks/usePageContainerWidthContext';
import WorkHeaderLogo from '../atoms/WorkHeaderLogo';

const WorkHeader: FC<WorkHeaderProps> = ({
  title,
  useCase = '',
  description,
  link = undefined,
  comment = undefined,
  style = {},
  ...restProps
}: WorkHeaderProps) => {
  const { isMobile, ratio } = usePageContainerWidthContext();
  const wrapperStyle = {
    ...style,
    paddingTop: `${(isMobile ? 32 : 90) * ratio}px`,
    paddingBottom: `${(isMobile ? 64 : 199) * ratio}px`,
    paddingLeft: `${(isMobile ? 32 : 145) * ratio}px`,
    paddingRight: `${(isMobile ? 32 : 145) * ratio}px`,
  };
  const logoStyle = {
    width: `${201 * ratio}px`,
    height: `${40 * ratio}px`,
    marginBottom: `${(isMobile ? 96 : 173) * ratio}px`,
  };
  const useCaseTextStyle = {
    fontSize: `${(isMobile ? 14 : 18) * ratio}px`,
    marginBottom: `${(isMobile ? 16 : 22) * ratio}px`,
  };
  const svgViewBoxProps = {
    viewBox: `0 0 ${(isMobile ? 375 - 32 * 2 : 1440 - 145 * 2) * ratio} ${(isMobile ? 36 : 50) *
      ratio}`,
  };
  const titleTextStyle = {
    fontSize: `${(isMobile ? 20 : 34) * ratio}px`,
  };
  const titleTextProps = {
    x: 0,
    y: (isMobile ? 18 : 25) * ratio,
  };
  const detailTextSectionStyle = {
    fontSize: `${(isMobile ? 14 : 18) * ratio}px`,
    lineHeight: '1.3em',
    marginTop: `${(isMobile ? 16 : 22) * ratio}px`,
  };
  const linkTextStyle = {
    fontSize: `${(isMobile ? 14 : 18) * ratio}px`,
    marginTop: `${(isMobile ? 32 : 98) * ratio}px`,
  };

  return (
    <WorkHeaderWrapper {...restProps} style={wrapperStyle}>
      <WorkHeaderLogo style={logoStyle} />
      <UseCaseText style={useCaseTextStyle}>USE CASE: {useCase.toUpperCase()}</UseCaseText>
      <TitleSvgContainer {...svgViewBoxProps}>
        <TitleText style={titleTextStyle} {...titleTextProps}>
          {title}
        </TitleText>
      </TitleSvgContainer>
      <p style={detailTextSectionStyle}>
        {description.split('\n').map(line => (
          <Fragment
            key={`fragment-${Math.random()
              .toString(36)
              .slice(2)}`}
          >
            {line}
            <br />
          </Fragment>
        ))}
      </p>
      {link && (
        <LinkText href={`//${link}`} style={linkTextStyle}>
          {link}
        </LinkText>
      )}

      {comment && <CommentText style={linkTextStyle}>{comment}</CommentText>}
    </WorkHeaderWrapper>
  );
};

const WorkHeaderWrapper = styled.div`
  width: 100%;
`;

const UseCaseText = styled.p`
  font-family: Replica-Bold;
  font-style: normal;
  font-weight: bold;
  line-height: 1.5em;
`;

const TitleSvgContainer = styled.svg.attrs(() => ({ xmlns: 'http://www.w3.org/2000/svg' }))`
  width: 100%;
  overflow: visible;
`;

const TitleText = styled.text.attrs(() => ({ dominantBaseline: 'central' }))`
  font-family: PragmaticaExtended-Black;
  letter-spacing: 0.215em;
  fill: black;
  stroke: white;
  stroke-width: ${4 / 34}em;
  paint-order: stroke;
  text-transform: uppercase;
`;

const LinkText = styled.a.attrs(() => ({ target: '_blank', rel: 'noopener noreferrer' }))`
  display: block;
  font-family: Replica-Bold;
  text-decoration-line: underline;
`;

const CommentText = styled.p`
  display: block;
  font-family: Replica-Bold;
  text-decoration-line: underline;
`;

export default WorkHeader;
