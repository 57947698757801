import React, { FC, PropsWithChildren } from 'react';
import 'destyle.css';

import GloablFontStyle from '../../fonts/GlobalFontsStyle';
import GloablStyle from '../../styles/GlobalStyle';
import GatsbySEO from '../../gatsby/GatsbySEO';
import { PageContainerWidthContextProvider } from '../../hooks/usePageContainerWidthContext';

type PageTemplateProps = PropsWithChildren<{ title: string }>;

const PageTemplate: FC<PageTemplateProps> = ({ children = null, title }: PageTemplateProps) => {
  return (
    <PageContainerWidthContextProvider>
      <GatsbySEO title={title} />
      <GloablFontStyle />
      <GloablStyle />
      {children}
    </PageContainerWidthContextProvider>
  );
};

export default PageTemplate;
