import React, { FC } from 'react';

import PageTemplate from './PageTemplate';
import WorkHeader from '../organisms/WorkHeader';
import GatsbyImage from '../../gatsby/GatsbyImage';
import JWPlayer from '../atoms/JWPlayer';
import YoutubeVideo from '../atoms/YoutubeVideo';
// import usePageContainerWidthContext from '../../hooks/usePageContainerWidthContext';

type WorkPageMedia = string | { jwplayer: JWPlayerSrc } | { youtube: YoutubeVideoSrc };

interface WorkPageData {
  data: { header: WorkHeaderProps; caseId: string; contents: WorkPageMedia[] };
}

// const WorkPageImage: FC = ({ imagePath }: { imagePath: string }) => {
//   const { width } = usePageContainerWidthContext();
// };

export default (({ data: { header, caseId, contents } }) => (
  <PageTemplate title={`Case: ${header.title}`}>
    <WorkHeader {...header} />
    {contents.map(media => {
      if (typeof media === 'string') {
        const imageId = `${caseId}-${media
          .split('.')
          .slice(0, -1)
          .join('.')}`;
        const imagePath = `work/${caseId}/${media}`;
        return <GatsbyImage key={imageId} imagePath={imagePath} style={{ width: '100%' }} />;
      }
      if (typeof media === 'object' && 'jwplayer' in media) {
        const videoId = `${caseId}-jw-${Math.random()
          .toString(36)
          .slice(2)}`;
        return <JWPlayer key={videoId} {...media.jwplayer} style={{ width: '100%' }} />;
      }
      if (typeof media === 'object' && 'youtube' in media) {
        const videoId = `${caseId}-yt-${Math.random()
          .toString(36)
          .slice(2)}`;
        return <YoutubeVideo key={videoId} {...media.youtube} />;
      }
      return null;
    })}
  </PageTemplate>
)) as FC<WorkPageData>;
