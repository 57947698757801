import React, { useContext, createContext, useRef, PropsWithChildren, FC } from 'react';
import styled from 'styled-components';
import useComponentSize from './useComponentSize';
import { deviceSizes } from '../styles/mediaDevice';

const mobileBreakpoint = deviceSizes.S;
const designReferenceWidth = {
  mobile: 375,
  desktop: 1440,
};

interface PageContainerWidth {
  width: number;
  isMobile: boolean;
  ratio: number;
}

const PageContainerWidthContext = createContext<PageContainerWidth>({
  width: 0,
  isMobile: false,
  ratio: 0,
});

const PageContainer = styled.div`
  width: 100%;
`;

export const PageContainerWidthContextProvider: FC<PropsWithChildren<PropsWithStyle>> = ({
  children = null,
  ...restProps
}: PropsWithChildren<PropsWithStyle>) => {
  const ref = useRef(null);
  const { width = 0 } = useComponentSize(ref);
  const isMobile = width < mobileBreakpoint;
  const ratio = width / (isMobile ? designReferenceWidth.mobile : designReferenceWidth.desktop);
  return (
    <PageContainerWidthContext.Provider value={{ width, isMobile, ratio }}>
      <PageContainer ref={ref} {...restProps}>
        {children}
      </PageContainer>
    </PageContainerWidthContext.Provider>
  );
};

export default (): PageContainerWidth => useContext(PageContainerWidthContext);
