import { createGlobalStyle } from 'styled-components';

// import ReplicaRegularTTF from './Replica-Regular.ttf';
// import ReplicaBoldTTF from './Replica-Bold.ttf';
import ReplicaRegularWOFF from './Replica-Regular.woff';
import ReplicaBoldWOFF from './Replica-Bold.woff';
import PragmaticaExtendedBlackWOFF from './PragmaticaExtended-Black.woff';

export default createGlobalStyle`
  @font-face {
    font-family: "Replica-Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Replica Regular"), local("Replica-Regular"), url(${ReplicaRegularWOFF}) format("woff");
  }
  @font-face {
    font-family: "Replica-Bold";
    font-style: normal;
    font-weight: normal;
    src: local("Replica Bold"), local("Replica-Bold"), url(${ReplicaBoldWOFF}) format("woff");
  }
  @font-face {
    font-family: "PragmaticaExtended-Black";
    font-style: normal;
    font-weight: normal;
    src: local("Pragmatica Extended Black"), local("PragmaticaExtended-Black"), url(${PragmaticaExtendedBlackWOFF}) format("woff");
  }
`;
